import React, { useState } from 'react';

import { Row } from "../layout/grid";
import Card from "../Card";

import { addToFavoriteList, removeFromFavoriteList, getLikedProducts } from '../../utils/actions/product'
// import { getId } from '../../utils/tools'

const RowsProducts = ({title, data: dataRows}) => {

  let likedProducts = getLikedProducts();
  likedProducts = Array.isArray(likedProducts)? likedProducts.map(product => product.slug) : [];
  let [ likedProductsState, setLikedProductsState ] = useState(likedProducts);

  const isInLikedList = slug => likedProductsState.includes(slug);

  const onClick = e => {
    let slug = e.currentTarget.id
    if( e.target.tagName === "IMG" ) {
      if( !isInLikedList(slug) ) {
        setLikedProductsState(prevState => ([...prevState, slug]))
      } else {
        setLikedProductsState(prevState => ([
          ...prevState.slice(0, prevState.indexOf(slug)),
          ...prevState.slice(prevState.indexOf(slug)+1),
        ]))
      }
    }

  }

  return (
    <>
    <h2 className="home-page-container__title container__main-title capitalize">{title}</h2>

    <div className="home-page-container__popular-products">

      {
        dataRows.map( (rows, i) => (
          <Row key={`popoular-products-row-${i}`}>
            {
              rows.length ?
                rows.map(product => {
                  let isLiked = isInLikedList(product.slug);
                  if(!isLiked)
                    return (
                      <Card
                        key={`popular-product-${product.slug}`}
                        onClick={onClick}
                        data={product}
                        isLiked={isLiked}
                        enableAction
                        actionEvent={[addToFavoriteList]}
                       />
                    )
                  else return (
                    <Card
                      key={`popular-product-${product.slug}`}
                      onClick={onClick}
                      data={product}
                      isLiked={isLiked}
                      removeEvent={removeFromFavoriteList}
                     />
                  )
                }) :
                <h3 className='favorite-products__resutls'>Oop's! Cette section est vide :(</h3>
            }
          </Row>
        ))
      }

    </div>
    </>
  )
}

export default RowsProducts;
