import React from 'react';
import {Link} from 'gatsby';
import { CarouselProvider, Slider, Slide, Image } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

const Carousel = ({data=[], providerClassName='', sliderClassName='', interval=3000, ...rest}) => (
  <CarouselProvider
    totalSlides={data.length}
    className={`${providerClassName}`}
    interval={interval}
    isPlaying={true}
  >
    <Slider className={`${sliderClassName}`}>
      {
        data.map((category, i) => (
          <Slide index={i} key={category.id} className='home-page-container__carousel-slide-item'>
            <Link to={`/category/${category.slug}`}>
              <Image
                src={category.image.src}
                className='home-page-container__carousel-image'
                alt={`${category.name}`}
                tag='div'
                isBgImage={true}
                />
            </Link>
          </Slide>
        ))
      }
    </Slider>
  </CarouselProvider>
);

export default Carousel;
