import React from 'react';

import { Row, Col } from "../layout/grid";

import Nike from '../../assets/images/brands/nike.svg'
import Addidas from '../../assets/images/brands/addidas.svg'
import Underarmour from '../../assets/images/brands/underarmour.svg'
import Puma from '../../assets/images/brands/puma.svg'

import Apple from '../../assets/images/brands/apple.svg'
import Lg from '../../assets/images/brands/lg.svg'
import Samsung from '../../assets/images/brands/samsung.svg'
import Condor from '../../assets/images/brands/condor.png'
//import Brandt from '../../assets/images/brands/brandt.svg'

const BrandsLogos = props => (
  <div className="brands-block">
    <Row>
      <Col s='3' className='center'>
        <div className="brand-block__image loading-background js-lazy-background" data-background={Nike}></div>
      </Col>
      <Col s='3' className='center'>
        <div className='brand-block__image loading-background js-lazy-background' data-background={Addidas}></div>
      </Col>
      <Col s='3' className='center'>
        <div className='brand-block__image loading-background js-lazy-background' data-background={Underarmour}></div>
      </Col>
      <Col s='3' className='center'>
        <div className='brand-block__image loading-background js-lazy-background' data-background={Puma}></div>
      </Col>
    </Row>
    <Row>
      <Col s='3' className='center'>
        <div className='brand-block__image loading-background js-lazy-background' data-background={Samsung}></div>
      </Col>
      <Col s='3' className='center'>
        <div className='brand-block__image loading-background js-lazy-background' data-background={Apple}></div>
      </Col>
      <Col s='3' className='center'>
        <div className='brand-block__image loading-background js-lazy-background' data-background={Lg}></div>
      </Col>
      <Col s='3' className='center'>
        <div className='brand-block__image loading-background js-lazy-background' data-background={Condor}></div>
      </Col>
    </Row>
  </div>
)

export default BrandsLogos;
