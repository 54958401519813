import React from "react"
import { graphql, Link, } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
//import CategoryItem from "../components/category/CategoryItem"
import { Row, Col } from "../components/layout/grid";
import RowsProducts from '../components/home/RowsProducts';
import MainCategoriesMenu from '../components/home/MainCategoriesMenu';
import Carousel from '../components/home/Carousel';
import BrandsLogos from '../components/home/BrandsLogos';

import { formatData, generateImagesSizes } from "../utils/tools";
import { getId } from "../utils/tools";

import '../assets/css/index.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

/**
 * @name IndexPage
 * represent the home page
 * @var {Array} getHotestCategories list of the categories contain more products
 */
const IndexPage = ({ navigate, data, location }) => {
  let { allWcProductsCategories: categories , allWcProducts: products } = data;
  /**
   * @name getHotestCategories is function list 5 categories ordered by count of products
   * @return {Array} list of categories object based on node of edges graphql response
   */
  let getHotestCategories = categories.edges.map(({node: category}) => category );
  let categoriesKeys = getHotestCategories.map(category => category.name);
  getHotestCategories = formatData(getHotestCategories || [], 5);
  /**
   * @name getMainCategories
   * retrieve a list of parent categories
   * so filter a list of categories by
   * display attribute and get all categories
   * has display equal to 'product'
   * @param {Array} categories list of categories
   *    that returned by graphql query response like [{node: {...}}]
   * @param {Integer} max maximum categories to retrieve
   * @return {Array} list of filter categories
   *    (has display attribute equal to 'product')
   */
  const getMainCategories = (categories, max=10) =>
     categories
      .map(({node: category}) => category)
      .filter(category => category.display === 'products')
      .slice(0, max);
  const mainCategories = getMainCategories(categories.edges||[], 12);

  /**
   * @name getPopularProducts
   * retrieve a list of popular product by
   * ordering products by number of sales
   * @param {Array} products list of products
   * @param {Integer} size the number of products to return
   * @return {Array} popular products
   */
   const getPopularProducts = (products, size = 5) => products.map(({node: product}) => product).slice(0, size);
   const popularProducts = formatData(getPopularProducts(products.edges, 16), 4);

  /**
   * retrieve a new products list
   * sorted data using _links.self.href
   * to get product id and then sort
   * the result by DESC
   * @param {Array} products list
   * @param {Integer} size the number of product
   * @return {Array} new products list
   */
  const getNewProducts = (products, size=5) => products.map(({node: product}) => product)
                                .sort((p1, p2) => ( getId(p2._links) - getId(p1._links) ) )
                                .slice(0, size);
  const newProducts = formatData(getNewProducts(products.edges, 16), 4);
  return (
    <Layout
      location={location}
      navigate={navigate}
      categories={data.allWcProductsCategories || {edges: []}}>
      <SEO
        title="Trouvez différents produits dont vous aurez besoin au quotidien"
        keywords={[`aalladine`, `ecommerce boutique`, ...categoriesKeys]} />

      <div className="home-page-container">

        <Row className="mt0 pl0">
          <Col s='4' m='3' className="pl0 pr0">
            <MainCategoriesMenu data={mainCategories} />
          </Col>
          <Col s='12' m='9' className='pl0 pr0'>
            <Carousel
              data={mainCategories.slice(0, 5) || []}
              providerClassName='home-page-container__carousel-container'
              sliderClassName='home-page-container__carousel-slider'
             />
          </Col>
        </Row>


        <div className="home-page-container__popular-categorie">
          <h2 className="container__main-title home-page-container__title mt0 mb0 capitalize">
            Categories Populaire
            <Link className="right" to="/categories-list" style={{ color: '#f30240', fontSize: 15}}>
              Voir plus&nbsp;
              <FontAwesomeIcon icon={faChevronRight} size="xs" />
            </Link>
          </h2>

          {
            formatData(mainCategories, 6).map((row, i) => (
              <Row className='mb0' key={`our-categories-${i}`}>
              {
                row.map(category => {
                  const images = generateImagesSizes(category.image.src);
                  return (
                    <Col s='4' m='2' className="home-page-container__category-item m0 p0" key={category.slug}>
                      <Link 
                        to={`/category/${category.slug}`} 
                        title={`${category.name}`}
                        className="home-page-container__category-link">
                        <div
                          className="home-page-container__category-image loading-background js-lazy-background"
                          data-background={images['150']}
                        />
                        <h2 className='home-page-container__category-title capitalize center mb0'>
                          <span>{category.name}</span>
                        </h2>
                      </Link>
                    </Col>
                  )
                })
              }
              </Row>
            ))
          }
        </div>

        <RowsProducts data={popularProducts} title='produits populaire' />
        {/** put some funcking things here **/}
        <RowsProducts data={newProducts} title='nouveaux produits' />

        <BrandsLogos />

      </div>

    </Layout>
  )
}

export default IndexPage;

export const pageQuery = graphql`
  query {
    allWcProducts (
      sort: { fields: [ total_sales, name ], order: DESC },
      limit: 16
     ) {
      edges {
        node {
          id
          total_sales
          name
          slug
          price
          regular_price
          average_rating
          rating_count
          _links{
            self{
              href
            }
          }
          acf {
            language
            brand
          }
          images {
            src
            id
          }
        }
      }
    }
    allWcProductsCategories (
      filter : { slug : {nin: ["uncategorized", "non-classe"]} },
      sort: { fields: [count, name], order: DESC }
     ) {
      edges {
       node {
         id
         name
         slug
         count
         display
         description
         _links {
           self {
             href
           }
         }
         image{
           id
           src
           name
           alt
         }
       }
      }
    }

  }
`
